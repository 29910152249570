import React from 'react';
import './Skills.css';
import { TbBrandReactNative, TbBrandMysql, TbCloud, TbJson } from 'react-icons/tb';
import { IoLogoJavascript, IoLogoSass } from 'react-icons/io';
import {SiCss3, SiExpress, SiMongodb, SiJquery, SiBulma, SiVisualstudiocode, SiPostman, SiInsomnia} from 'react-icons/si';
import {FaHtml5, FaNodeJs, FaGitAlt, FaBootstrap, FaChrome} from 'react-icons/fa';
import {AiFillGithub, AiFillGitlab} from 'react-icons/ai';
import {DiHeroku} from 'react-icons/di';


const Skills = () => {
  return (
    <section id="skill" className='skills container section'>
      
      <div className="sectionTitle">
        <span className="titleNumber">02.</span>
        <h5 className="titleText">Skills
          <div className="underline"><span></span></div>
        </h5>
      </div>
    
      <div className="skillsContainer grid">
        <div className="skillGroup">
          <div className="groupTitle">
            <h2 className="title">Web Development</h2>
              <span className="subTitle">
                1-2 Years Experience
              </span>
          </div>

          <div className="generalSkills">

          <div className="singleSkill">
              <div className="iconBox flex">
                <FaHtml5 className='icon'/>
              </div>
              <span className='skillName'>HTML</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <SiCss3 className='icon'/>
              </div>
              <span className='skillName'>CSS</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <IoLogoJavascript className='icon'/>
              </div>
              <span className='skillName'>JavaScript</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <TbBrandReactNative className='icon'/>
              </div>
              <span className='skillName'>React</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <SiJquery className='icon'/>
              </div>
              <span className='skillName'>jQuery</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <IoLogoSass className='icon'/>
              </div>
              <span className='skillName'>Sass</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <FaBootstrap className='icon'/>
              </div>
              <span className='skillName'>Bootstrap</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <SiBulma className='icon'/>
              </div>
              <span className='skillName'>Bulma</span>
            </div>

          </div>
        </div>

        <div className="skillGroup">
          <div className="groupTitle">
            <h2 className="title">Back End</h2>
              <span className="subTitle">
                1-2 Years Experience
              </span>
          </div>
          <div className="generalSkills">

            <div className="singleSkill">
              <div className="iconBox flex">
                <SiMongodb className='icon'/>
              </div>
              <span className='skillName'>MongoDB</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <SiExpress className='icon'/>
              </div>
              <span className='skillName'>Express</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <FaNodeJs className='icon'/>
              </div>
              <span className='skillName'>Node.js</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <TbBrandMysql className='icon'/>
              </div>
              <span className='skillName'>MySQL</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <TbCloud className='icon'/>
              </div>
              <span className='skillName'>RESTful API</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <TbJson className='icon'/>
              </div>
              <span className='skillName'>JSON</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <SiPostman className='icon'/>
              </div>
              <span className='skillName'>Postman</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <SiInsomnia className='icon'/>
              </div>
              <span className='skillName'>Insomnia</span>
            </div>
            
          </div>
        </div>
        <div className="skillGroup">
          <div className="groupTitle">
            <h2 className="title">Other</h2>
              <span className="subTitle">
                1-2 Years Experience
              </span>
          </div>
          <div className="generalSkills">

            <div className="singleSkill">
              <div className="iconBox flex">
                <SiVisualstudiocode className='icon'/>
              </div>
              <span className='skillName'>VS Code</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <FaChrome className='icon'/>
              </div>
              <span className='skillName'>DevTools</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <AiFillGithub className='icon'/>
              </div>
              <span className='skillName'>GitHub</span>
            </div>

            <div className="singleSkill">
              <div className="iconBox flex">
                <DiHeroku className='icon'/>
              </div>
              <span className='skillName'>Heroku</span>
            </div>

          </div>
      </div>

      </div>

    </section>
  )
}

export default Skills