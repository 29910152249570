import React from 'react';
import './Footer.css';


const Footer = () => {
  return (
    <section className="footer">
      <span>Swaathy Selvagnanam 2023</span>
    </section>
  )
}

export default Footer

