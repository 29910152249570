import React from 'react';
import './About.css';
import {TbCloudDownload} from 'react-icons/tb';
import img from '../../Assets/ai-image.jpg';


const About = () => {
  return (
    <section id='about' className='about section container'>
      
      <div className="sectionTitle">
        <span className="titleNumber">01.</span>
        <h5 className="titleText">About Me
          <div className="underline"><span></span></div>
        </h5>
      </div>

      <div className="sectionContent grid">
        <div className="textSection">
          <h4>
          As a full-stack web developer, I excel in designing, building, and maintaining high-quality code throughout the entire development process. I pride myself on staying highly-motivated and give great attention to detail no matter the complexity of the project. I am a quick learner and often find myself exploring different JavaScript frameworks and libraries. I am eager to join a solution-driven team, where I can tackle complex challenges and contribute to the development of innovative software with a lasting impact.
          </h4>

          <div className="aboutBtn">
            <a href='SwaathySelva-2023Resume.pdf' download='SwaathySelva-2023Resume.pdf' className='flex'>
              Download Resume <TbCloudDownload className='icon'/>
            </a>
          </div>
        </div>

        <div className="aboutImgDiv">
          <img src={img} alt='Swaathy Selva' className='aboutImg'/>
        </div>
      </div>
    </section>
  )
}

export default About

