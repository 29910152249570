import React from 'react';
import './Projects.css';
import { AiFillYoutube } from 'react-icons/ai';
import { FiGithub, FiExternalLink } from 'react-icons/fi';
// import img6 from '../../Assets/img6.png';
import Wanderlust from '../../Assets/wanderlustScreenshot.png';
import Cinescape from '../../Assets/cinescapeScreenshot.png';
import Scheduler from '../../Assets/schedulerScreenshot.png';
import Recipe from '../../Assets/recipeScreenshot.png'

const data =[
  {
    id: 1,
    image: Wanderlust,
    liveLink: 'https://wanderlustblog-be4280eec6bb.herokuapp.com/',
    github: 'https://github.com/sselvag/TravelBlog4',
    title: 'Wanderlust Travel Blog',
    desc: 'A full stack MERN application designed with CRUD operations for travellers to blog and share their journeys.',
    tech1: 'MERN',
    tech2: 'Mongoose',
    tech3: 'REST API',
    tech4:'Heroku',
  },

  {
    id: 2,
    image: Cinescape,
    liveLink: 'https://sselvag.github.io/MovieReview/',
    github: 'https://github.com/sselvag/MovieReview',
    title: 'Cinescape Movie Review',
    desc: 'A front-end application that uses 3rd party movie APIs for users to search and learn more about their favourite movies.',
    tech1: 'Javascript',
    tech2:'Local Storage',
    tech3: 'Fetch API',
    tech4: 'Moment.js',
  },

  {
    id: 3,
    image: Scheduler,
    liveLink: 'https://sselvag.github.io/Scheduler/',
    github: 'https://github.com/sselvag/Scheduler',
    title: 'Digital Planner and Calendar',
    desc: 'This calendar app excels in event management, utilizing arrays, event listeners, local storage, and modular functions for users to effortlessly add, view, and delete events.',
    tech1: 'JavaScript',
    tech2: 'Local Storage',
    tech3:'RegEx',
    tech4: 'Github Pages'
  },

  {
    id: 3,
    image: Recipe,
    liveLink: 'https://sselvag.github.io/RecipeGenerator/',
    github: 'https://github.com/sselvag/RecipeGenerator',
    title: 'The Recipe Generator',
    desc: 'This recipe generator allows users to search for meals based on specific ingredients using the MealDB API.',
    tech1: 'HTML',
    tech2: 'CSS',
    tech3: 'Javascript',
    tech4: 'Fetch API',
  }
]

const Projects = () => {
  return (
    <section id="projects" className='projects container section'>

      <div className="sectionTitle">
        <span className="titleNumber">03.</span>
        <h5 className="titleText">Projects
          <div className="underline"><span></span></div>
        </h5>
      </div>

      <div className="projectContainer grid">
        {
          data.map(({id, github, image, liveLink, desc, demo, title, tech1, tech2, tech3, tech4}) => {
            return(
              <div key={id} className="singleProject">
                <div className="externalLinks flex">
                  <div className="youtubeIconAndLiveLink">
                    <a href={github}>
                      <FiGithub className='icon'/>
                    </a>
                  </div>
                  <div className="githubIcon">
                    <a href={liveLink}>
                      <FiExternalLink className='icon'/>
                    </a>
                  </div>
                </div>

                <div className="imgDiv">
                  <a href={liveLink}>
                    <img src={image} alt={title}/>
                  </a>
                </div>

                <div className="projectTitle">
                  <a href={liveLink}>
                    <h3>{title}</h3>
                  </a>
                </div>

                <div className="desc">
                  {desc}
                </div>

                <div className="technologies flex">
                  <small>{tech1}</small>
                  <small>{tech2}</small>
                  <small>{tech3}</small>
                  <small>{tech4}</small>
                </div>
              </div>
            )
          })
        }
      </div>
    
    </section>
  )
}

export default Projects

