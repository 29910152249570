import React, {useState} from 'react';
import './Navbar.css';
import {AiFillCloseCircle} from 'react-icons/ai';
import {TbGridDots} from 'react-icons/tb';
import logo from '../../Assets/icons8-developer-60.png';


const Navbar = () => {

    // const [active, setActive] = useState('navBar');

    // const showNavBar = () =>{
    //     setactiveHeader('');
    //     setActive('navBar activeNavbar');
    // };

    // const removeNavBar = () =>{
    //     setactiveHeader('header');
    //     setActive('navBar');
    // };

    const showNavBar = () => {
        var element = document.getElementById('menu');
        element.classList.add('activeNavBar');
    };

    const removeNavBar = () => {
        var element = document.getElementById('menu');
        element.classList.remove('activeNavBar');
    };
    
    const [activeHeader, setactiveHeader] = useState('header');
    
    const addBg = () =>{
        if(window.scrollY >= 10){
            setactiveHeader('header activeHeader')
        }
        else {
            setactiveHeader('header')
        }
    };

    window.addEventListener('scroll', addBg);
  
    return (
    <header className={activeHeader}>
        <div className='logoDiv'>
            <a href='#home'>
                <img src={logo} alt='Swaathy Selva' className='logo'/>
            </a>
            {/* <h1 className='logo'><a href='#home'>SS.</a></h1> */}
        </div>

        <div className='navBar' id='menu'>
            <ul className='navLists'>
                <li className='navItem'>
                    <a href='#about' className='navLink'><span className='headerNumber'>1.</span>About</a>
                </li>
                <li className='navItem'>
                    <a href='#skill' className='navLink'><span className='headerNumber'>2.</span>Skills</a>
                </li>
                <li className='navItem'>
                    <a href='#projects' className='navLink'><span className='headerNumber'>3.</span>Projects</a>
                </li>
                <li className='navItem'>
                    <a href='#contact' className='navLink'><span className='headerNumber'>4.</span>Contact</a>
                </li>
                <button className='btn'><a href='SwaathySelva-2023Resume.pdf' target='_blank'>Resume</a></button>
            </ul>

            <div onClick={removeNavBar} className='closeNavBar'>
                <AiFillCloseCircle className='icon'/>
            </div>
        </div>

        <div onClick={showNavBar} className='toggleNavBar'>
            <TbGridDots className='icon'/>
        </div>

    </header>
  )
}

export default Navbar




