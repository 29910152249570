import React, { useRef } from 'react';
import './Contact.css';
// import { BsInstagram } from 'react-icons/bs';
// import { TbArrowBigRightLines } from 'react-icons/tb';


import emailjs from 'emailjs-com';


const Contact = () => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_eumqrkh', 'template_hpwcuof', form.current, 'H0TwvuXGP8EuXhFvD')
      e.target.reset()
  };

  return (
    <section id="contact" className="contact section container">
      
      <div className="sectionTitle">
        <span className="titleNumber">04.</span>
        <h5 className="titleText">Contact
          <div className="underline"><span></span></div>
        </h5>
      </div>

      <div className="contactContainer grid">
        {/* <div className="socialContatcs grid">
          <h3>Talk to me</h3>

          <div className="cards grid">
            <div className="card">
              <div>
                <BsInstagram className='icon'/>
              </div>
              <h4>Instagram</h4>
              <span className="userName">
                @unknown
              </span>

              <div>
                <a href='https://www.instagram.com/' className='flex'>
                  Send Message
                  <TbArrowBigRightLines className='icon'/>
                </a>
              </div>
            </div>

            <div className="card">
              <div>
                <BsInstagram className='icon'/>
              </div>
              <h4>Instagram</h4>
              <span className="userName">
                @unknown
              </span>

              <div>
                <a href='https://www.instagram.com/' className='flex'>
                  Send Message
                  <TbArrowBigRightLines className='icon'/>
                </a>
              </div>
            </div>

            <div className="card">
              <div>
                <BsInstagram className='icon'/>
              </div>
              <h4>Instagram</h4>
              <span className="userName">
                @unknown
              </span>

              <div>
                <a href='https://www.instagram.com/' className='flex'>
                  Send Message
                  <TbArrowBigRightLines className='icon'/>
                </a>
              </div>
            </div>
          </div>
        </div>  */}

        <div className="form grid">
          <h3>Get in touch</h3>

          <form ref={form} onSubmit={sendEmail}>
            <input type="text" placeholder='Enter your name' name='name'/>
            <input type="email" placeholder='Enter your email' name='email'/>
            <textarea name="message" placeholder='Enter your message'/>
            <button className='formBtn' type='submit' name='submit'>
              Send email
            </button>
          </form>
        </div>

       </div>

    </section>
  )
}

export default Contact

