import React from 'react';
import './Home.css';
import {AiFillGithub} from 'react-icons/ai';
import {FiYoutube} from 'react-icons/fi';
import { TbArrowBigRightLine } from 'react-icons/tb';

const Home = () => {
  return (
    <section id="home" className='home section'>

       <div className='leftIcons'>
        <div className='socials grid'>
          <a href='https://www.github.com/sselvag'>
            <AiFillGithub className='icon'/>
          </a>
          <div className='line'></div>
        </div>
      </div>


      <div className='container homeContainer'>
        
        <span className="introText">
          Hi, my name is
        </span>

        <h1 className='title'>
          Swaathy Selvagnanam
        </h1>

        <p className="homeParagraph">
        I am a developer who specializes in building full-stack web applications. I am currently on the market for JavaScript developer positions.
        </p>

        <span className="subtitle">
          Please scroll down to get to know me a little better!
        </span>

        <div className="lowerHomeSection">
          <button className='contactBtn'>
            <a href='#contact' className='flex'>
              Contact Me <TbArrowBigRightLine className='icon'/>
            </a>
          </button>
        </div>

      </div>

      <div className="rightEmail">
        <div>
          <div className="emailAddress">
            <a href='https://mailto:swaathy.selva@gmail.com' target='_blank'rel="noreferrer">
              swaathy.selva@gmail.com
            </a>
          </div>

          <div className="line"></div>
        </div>
      </div>

    </section>
  )
}

export default Home

